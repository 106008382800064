import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {UserAccountManagementService} from '../../user-account-management/user-account-management.service';
import {UserRole} from '../../models/user-role.enum';

@Directive({
	selector: '[agaEnableIfUserAuthorized]'
})

export class EnableIfUserAuthorizedDirective implements OnInit {
	@Input('agaEnableIfUserAuthorized') authorizedUserRoles: Array<UserRole>;
	@Input() disableChildren = true;

	constructor(private elementRef: ElementRef,
	            private userAccountManagementService: UserAccountManagementService,
	            private renderer: Renderer2) {
	}

	ngOnInit(): void {
		if (!this.userAccountManagementService.isUserAuthorized(this.authorizedUserRoles)) {
			this.disableElement();
		}
	}

	disableElement(): void {
		this.renderer.setAttribute(this.elementRef.nativeElement, 'disabled', 'true');
		this.renderer.setStyle(this.elementRef.nativeElement, 'opacity', '0.8');
		this.renderer.setStyle(this.elementRef.nativeElement, 'cursor', 'not-allowed');
		this.renderer.setStyle(this.elementRef.nativeElement, 'pointer-events', 'none');

		// Disable nested children
		if (this.disableChildren) {
			Array.from(this.elementRef.nativeElement.getElementsByTagName('*')).forEach((element: HTMLElement) => {
				element.setAttribute('disabled', 'true');
				element.style.opacity = '0.8';
				element.style.pointerEvents = 'none';
			});
		}
	}
}
