import {NgModule} from '@angular/core';
import {AuthorizationGuard} from './authorization-guard';
import {ShowIfUserAuthorizedDirective} from './directives/show-if-user-authorized.directive';
import {EnableIfUserAuthorizedDirective} from './directives/enable-if-user-authorized.directive';

@NgModule(({
	declarations: [ShowIfUserAuthorizedDirective, EnableIfUserAuthorizedDirective],
	providers: [AuthorizationGuard],
	exports: [ShowIfUserAuthorizedDirective, EnableIfUserAuthorizedDirective]
}))
export class AuthorizationModule {
}
